.cqc__navbar {
    display: flex;
    flex-direction: column;
    padding: 1rem  1rem 0rem;
    background: #021649;
}


.cqc__navbar_frst {
    display:flex;
    flex-direction: row;
}

.cqc__navbar_frst-empty {
    display: flex;
    flex: 1;
}
.cqc__navbar_frst-logo {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cqc__navbar_frst-end {
    display: flex;
    flex: 1 ;
    flex-direction: row;
    justify-content: flex-end;
}

.cqc__navbar_second {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    border-bottom: 1px solid #fff;
}

.cqc__navbar_second p {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.cqc__navbar-links_container  {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;

    
    cursor: pointer;
}

.cqc__navbar-links_container2 {
    color: #b9bfff;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
   
    cursor: pointer;
}
.cqc__navbar-links_container p:hover {
    color: #FF4820;
}

.cqc__navbar_frst-sign {
    display: none;
    flex-direction: row;
    align-items: center;
}
.cqc__navbar_frst-sign p,
.cqc__navbar_frst-menu_container p {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}


.cqc__navbar_frst-logo p {
    color: #fff;
    font-weight: 500;
    font-size: 40px;
    line-height: 37px;
    text-transform: capitalize;
    padding-bottom: 0.5rem;
    margin: 0 1rem;
    cursor: pointer;
}


.cqc__navbar_frst-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.cqc__navbar_frst-menu svg {
    cursor: pointer;
}

.cqc__navbar_frst-menu_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--color-footer);
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 2rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.cqc__navbar_frst-menu_container p {
    margin: 1rem 0;
}

.cqc__navbar_frst-menu_containers-links-sign {
    display: none;
}

.login_div{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.login_div p{
    color: white;
    margin-left: 0.5rem;
    margin-right: 2rem;
    margin-top: 0;
    margin-bottom: 0;
}

.shoping_cart_container{
    display: flex;
    flex-direction: row;
    color: white;
    margin-right: 10px;
    align-items: center;
}

.shoping_cart{
    height: 1.6rem;
    width: 1.6rem;
}

.biuser :hover {
    cursor: pointer;
}

.login_button {
    color: white;
    background: red;
    line-height: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    width: 6.5rem;
}



@media screen and  (max-width : 1100px) {
    .cqc__navbar-links_container {
        font-size: 18px; 
    }
    .cqc__navbar-links_container2  {
         font-size: 22px; 
    }
}

@media screen and  (max-width : 950px) {
    .cqc__navbar_second  {
        display: none;
    }
    .cqc__navbar_frst-menu {
        display: flex;
    }

    .cqc__navbar {
        padding: 1rem  1rem 1rem;
    }
    .cqc__navbar-links_container {
        font-size: 16px; 
    }
    .cqc__navbar-links_container2  {
         font-size: 20px; 
    }
}

@media screen and  (max-width : 886px) {
    .cqc__navbar_frst-empty {
        display: none;
    }
}
@media screen and  (max-width : 750px) {
    .cqc__navbar_frst-logo p {
        font-size: 27px;
    }
}

@media screen and  (min-width : 695px) {
    .cqc__navbar_frst-sign {
        display: flex;
    }
}

@media screen and  (max-width : 695px) {
    .cqc__navbar_frst-menu_containers-links-sign  {
        display: flex;
    }
    .cqc__navbar_frst-menu_containers-links-sign p{
        margin-right: 1rem ;
    }
    .cqc__navbar_frst-menu_containers-links-sign button{
        margin: 0.5rem 0 ;
    }

    .cqc__navbar_frst-menu {
        align-items: center;
    }
}

@media screen and  (max-width : 550px) {
    .cqc__navbar_frst-logo p {
        font-size: 25px;
        margin-left: 0.3rem;
        margin-right: 0.8;
    }
    .cqc__navbar_frst-menu svg {
        height: 24px;
        width: 24px;
    }

    .cqc__navbar {
        padding: 0.5rem  0.5rem 0.5rem;
    }
    .cqc__navbar_frst-menu_container {
        margin-top: 1.5rem;
    }
}

@media screen and  (max-width : 445px) {
    .cqc__navbar_frst-logo p {
        font-size: 22px;
    }

    .cqc__navbar_frst-menu svg {
        height: 22px;
        width: 22px;
    }
}

@media screen and  (max-width : 402px) {
    .cqc__navbar_frst-logo p {
        font-size: 20px;
    }
    .cqc__navbar_frst-menu svg {
        height: 20px;
        width: 20px;
    }
}

@media screen and  (max-width : 372px) {
    .cqc__navbar_frst-menu  {
        align-items: flex-start;
        padding-top: 0.5rem;
    }
}

@media screen and  (max-width : 354px) {
    .cqc__navbar_frst-logo p {
        font-size: 18px;
        margin-left: 0.2rem;
        margin-right: 0.7;
    }
    .cqc__navbar_frst-menu svg {
        height: 19px;
        width: 19x;
    }
}