.web_background {
    padding: 2rem 10rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background-color: white;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(back.jpeg);
}

.web_title {
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.web_title h1 {
    width: 100%;
    margin-top: 0;
    text-align: center;
    font-size: 40px;
}

.web_title h2 {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    font-size: 28px;
}

.web_title p {
    width: 55%;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: start;
}

.web_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30rem;
    width: 30%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
    margin-bottom: 1rem;
}

.web_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
}

.web_row2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.web_item2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 35rem;
    width: 60%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
}

.web_item2_div {
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30rem;
}

.web_item2_div h2 {
    margin: 0;
    color: black;
}

.web_item2_div p {
    margin: 0;
    color: black;
}

.web_item2_div h1 {
    margin: 0;
    color: black;
}

.web_item2 img {
    height: 70%;
    width: 100%;
    object-fit: contain;
}

.errortext {
    color: red;
}

.reg-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.reg-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.web_diffuser_section{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: top;
    height: 61rem;
    align-self: center;
    color: black;
    margin-top: 30px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
}


.web_diffuser_section h1{
    font-weight: normal;
}

.web_diffuser_img {
    width: 400px;
    align-self: center;
    border: 1px solid black;
}

.web_diffuser_section p {
    width: 70%;
    align-self: center;
    text-align: start;
    
}

.web_diffuser_section table {
    width: 500px;
    align-self: center;
}

.web_diffuser_section input {
    width: 40px;
    align-self: center;
}

.web_discount_img {
    width: 450px;
    margin-top: 10px;
    align-self: center;
}

.checkout_button {
    padding: 0.5rem 1rem;
    margin: 30px 15px 15px 15px; 
    color: white;
    background: #163278;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    width: 450px;
    align-self: center;
}


.web_tele_section{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: top;
    height: 65rem;
    align-self: center;
    color: black;
    margin-top: 30px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
}
.web_tele_section h1{
    font-weight: normal;
}

.web_tele_img {
    width: 400px;
    align-self: center;
    min-height: 533px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.web_tele_section p {
    width: 80%;
    align-self: center;
    text-align: start;
}

.web_tele_section table {
    width: 700px;
    align-self: center;
}

.web_tele_section input {
    width: 40px;
    align-self: center;
}

.web_micro_section{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: top;
    height: 50rem;
    align-self: center;
    color: black;
    margin-top: 30px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
}
.web_micro_section h1{
    font-weight: normal;
}

.web_micro_section table {
    width: 700px;
    align-self: center;
}

.web_micro_section input {
    width: 40px;
    align-self: center;
}

.web_micro_section p {
    width: 80%;
    align-self: center;
    text-align: start;
}

.web_button_row{
    display: flex;
    flex-direction: row;
    align-self: center;
    color: black;
    width: 450px;
}

.image_text_row{
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 70%;
    justify-content: space-around;
    gap: 2rem;
}

.image_text_row_coin{
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 75%;
    justify-content: center;
    text-align: start;
    gap: 1rem;
}


.web_hand_section{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: top;
    height: 61rem;
    align-self: center;
    color: black;
    margin-top: 30px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
}

.web_hand_section h1{
    font-weight: normal;
}

.web_hand_section p {
    width: 60%;
    align-self: center;
    text-align: start;
}

.web_hand_section table {
    width: 700px;
    align-self: center;
}

.web_hand_section input {
    width: 40px;
    align-self: center;
}

.web_token_section{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: top;
    height: 60rem;
    align-self: center;
    color: black;
    margin-top: 30px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
}

.web_token_section h1{
    font-weight: normal;
}

.web_diffuser_section h1{
    font-weight: normal;
}

.web_token_section table {
    width: 400px;
    align-self: center;
}

.web_token_section input {
    width: 40px;
    align-self: center;
}

.web_token_section p {
    width: 60%;
    align-self: center;
    text-align: start;
}

.supBig {
    font-size: 16px;
}

.supSmall {
    font-size: 8px;
}

.web_background table {
	width: 60%;
	border-collapse: collapse;
    
}

.web_background td {
	padding: 10px;
	border-bottom: 1px solid #ccc;
    font-size: 18px;
}

.web_background td:first-child {
	font-weight: bold;
}